import request from "@/utils/request";

// 生成制证申请id
export function GenerateMakeCard (data) {
  return request({
    url: "MakeCard/GenerateMakeCard",
    method: "post",
    data,
  });
}

// 生成制证申请信息
export function SubmitMakeCard (data) {
  return request({
    url: "MakeCard/SubmitMakeCard",
    method: "post",
    data,
  });
}

// 获取我正在起草中的制证申请
export function GetDraftMakeCard (params) {
  return request({
    url: "MakeCard/GetDraftMakeCard",
    method: "get",
    params,
  });
}

// 判断是否存在正在审核中的制证申请
export function ExistMakingApply (params) {
  return request({
    url: "MakeCard/ExistMakingApply",
    method: "get",
    params,
  });
}

// 初始化并加载审批节点（主要用于起草时进行加载）
export function InitFlowNode (params) {
  return request({
    url: "FlowInstance/InitFlowNode",
    method: "get",
    params,
  });
}

// 扫码支付
export function PaymentByCode (data) {
  return request({
    url: "MakeCard/PaymentByCode",
    method: "post",
    data,
  });
}

// 获取制证申请列表
export function GetMyMakeCard (params) {
  return request({
    url: "MakeCard/GetMyMakeCard",
    method: "get",
    params,
  });
}

// 重新发起微信支付，用于待支付和支付失败的制证申请
export function RePaymentByWechat (data) {
  return request({
    url: "MakeCard/RePaymentByWechat",
    method: "post",
    data,
  });
}

// 终止制证申请信息
export function StopMakeCard (data) {
  return request({
    url: "MakeCard/StopMakeCard",
    method: "post",
    data,
  });
}

// 制证退款，当退款失败时有效
export function Refund (data) {
  return request({
    url: "MakeCard/Refund",
    method: "post",
    data,
  });
}


// 通过身份证号获取队员详情
export function GetWxGetMemberInfo (params) {
  return request({
    url: "TeMember/WxGetMemberInfo",
    method: "get",
    params,
  });
}